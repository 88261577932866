<template>
    <div>
        <ts-page-title
            :title="$t('companyCompensate.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('companyCompensate.pageTitle'),
                    href: '/admin/finance/company-compensations/create'
                },
                {
                    text: $t('create'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                    <div class="tw-flex tw-space-x-2 tw-mb-3">
                        <ts-button color="primary" @click.prevent="onSave">{{
                            $t("save")
                        }}</ts-button>
                        <ts-button
                            @click.prevent="
                                () =>
                                    $router.push(
                                        '/admin/finance/company-compensations'
                                    )
                            "
                        >
                            {{ $t("cancel") }}
                        </ts-button>
                    </div>
                    <div class="tw-w-full tw-h-96 tw-overflow-y-scroll">
                        <table class="tw-w-full">
                            <thead>
                                <tr class="tw-bg-blue-400 tw-text-white">
                                    <th
                                        rowspan="2"
                                        class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border tw-uppercase tw-text-center"
                                    >
                                        {{ $t("numeric") }}
                                    </th>
                                    <th rowspan="2" class="tw-text-center">
                                        <input
                                            @change="checkAll"
                                            type="checkbox"
                                            :checked="isCheckAll"
                                        />
                                    </th>
                                    <th
                                        rowspan="2"
                                        class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border tw-uppercase"
                                    >
                                        {{ $t("driverClearCash.voucherNo") }}
                                    </th>
                                    <th
                                        rowspan="2"
                                        class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border tw-uppercase"
                                    >
                                        {{ $t("driverClearCash.driverCode") }}
                                    </th>
                                    <th
                                        rowspan="2"
                                        class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border tw-uppercase"
                                    >
                                        {{ $t("driverClearCash.driverName") }}
                                    </th>
                                    <th
                                        rowspan="2"
                                        class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border tw-uppercase"
                                    >
                                        {{ $t("driverClearCash.phone") }}
                                    </th>
                                    <th
                                        colspan="3"
                                        class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border tw-uppercase tw-text-center"
                                    >
                                        {{ $t("driverClearCash.collected") }}
                                    </th>
                                    <th
                                        rowspan="2"
                                        class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border tw-uppercase tw-text-center"
                                    >
                                        {{ $t("driverClearCash.taxiFee") }}
                                    </th>
                                    <th
                                        colspan="2"
                                        class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border tw-uppercase tw-text-center"
                                    >
                                        {{
                                            $t(
                                                "driverClearCash.amountTobeClear"
                                            )
                                        }}
                                    </th>
                                </tr>
                                <tr>
                                    <th
                                        class="tw-px-2 tw-py-1 tw-border tw-text-center tw-w-11 tw-whitespace-nowrap"
                                    >
                                        {{ $t("driverClearCash.totalCash") }}
                                    </th>
                                    <th
                                        class="tw-px-2 tw-py-1 tw-border tw-text-center"
                                    >
                                        {{ $t("driverClearCash.usd") }}
                                    </th>
                                    <th
                                        class="tw-px-2 tw-py-1 tw-border tw-text-center"
                                    >
                                        {{ $t("driverClearCash.riel") }}
                                    </th>

                                    <th
                                        class="tw-px-2 tw-py-1 tw-border tw-text-center"
                                    >
                                        {{ $t("driverClearCash.usd") }}
                                    </th>
                                    <th
                                        class="tw-px-2 tw-py-1 tw-border tw-text-center"
                                    >
                                        {{ $t("driverClearCash.riel") }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(p, index) in packages" :key="index">
                                    <td
                                        class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-text-center"
                                    >
                                        {{ index + 1 }}
                                    </td>
                                    <td
                                        class="tw-text-center tw-px-2 tw-py-1 tw-border"
                                    >
                                        <input
                                            type="checkbox"
                                            v-model="
                                                model.driver_clear_cash_id_list
                                            "
                                            :value="p.driver_clear_cash_id"
                                        />
                                    </td>
                                    <td
                                        class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border"
                                    >
                                        {{ p.voucher_no }}
                                    </td>
                                    <td
                                        class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border"
                                    >
                                        {{ p.driver_code }}
                                    </td>
                                    <td
                                        class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border"
                                    >
                                        {{ p.driver_name }}
                                    </td>
                                    <td
                                        class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border"
                                    >
                                        {{ p.contact_no }}
                                    </td>
                                    <td
                                        class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border"
                                    >
                                        <ts-accounting-format
                                            :digit="2"
                                            :value="p.amount_tobe_collected"
                                        />
                                    </td>
                                    <td
                                        class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border"
                                    >
                                        <ts-accounting-format
                                            :digit="2"
                                            :value="p.amount_tobe_cleared_usd"
                                        />
                                    </td>
                                    <td
                                        class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border"
                                    >
                                        <ts-accounting-format
                                            :digit="2"
                                            :value="p.amount_tobe_cleared_khr"
                                        />
                                    </td>
                                    <td
                                        class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border"
                                    >
                                        <ts-accounting-format
                                            currency="KHR"
                                            :digit="0"
                                            :value="p.taxi_fee"
                                        />
                                    </td>
                                    <td
                                        class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-uppercase"
                                    >
                                        <ts-accounting-format
                                            :digit="2"
                                            :value="p.amount_tobe_cleared_usd"
                                        />
                                    </td>
                                    <td
                                        class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-uppercase"
                                    >
                                        <ts-accounting-format
                                            currency="KHR"
                                            :digit="0"
                                            :value="p.amount_tobe_cleared_khr"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                    </div>
                    <div class="tw-w-64">
                        <textarea
                            v-model="model.remark"
                            cols="30"
                            rows="2"
                            class="form-control"
                            :placeholder="$t('driverClearCash.remark')"
                        ></textarea>
                    </div>
            </ts-panel-wrapper>
        </ts-panel>
        <a-modal
            :title="$t('pdf')"
            width="50%"
            v-model="preview_pdf"
            :footer="null"
        >
            <ts-preview-pdf v-model="loading" :src="src" />
        </a-modal>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import { Errors } from "form-backend-validation";
export default {
    name: "companyCompensate",
    data() {
        return {
            packages: [],
            loading: false,
            errors: new Errors(),
            preview_pdf: false,
            src: "",
            model: {
                driver_clear_cash_id_list: [],
                remark: ""
            }
        };
    },
    computed: {
        isCheckAll() {
            if (this.model.driver_clear_cash_id_list.length) {
                return (
                    this.model.driver_clear_cash_id_list.length ==
                    this.packages.length
                );
            }
            return false;
        }
    },
    methods: {
        ...mapActions("finance/companyCompensate", [
            "driverClearCash",
            "store"
        ]),
        getClearedCash() {
            this.loading = true;
            this.driverClearCash()
                .then(response => {
                    this.packages = response.data;
                    this.pushAll();
                })
                .catch(err => {
                    this.$notify({ type: "error", text: err.message });
                })
                .finally(() => (this.loading = false));
        },
        checkAll(e) {
            if (!e.target.checked) {
                this.model.driver_clear_cash_id_list = [];
                return;
            }
            this.pushAll();
        },
        pushAll() {
            this.packages.forEach(element => {
                this.model.driver_clear_cash_id_list.push(
                    element.driver_clear_cash_id
                );
            });
        },
        onSave() {
            this.loading = true;
            this.preview_pdf = true;
            this.store(this.model)
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
                    this.clearInput();
                    this.src = response.url;
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                    this.errors = new Errors(error.errors);
                    this.preview_pdf = false;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
				clearInput(){
					this.packages = []
					this.model.driver_clear_cash_id_list = []
					this.model.remark = ''
				}
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.getClearedCash();
        });
    }
};
</script>
